// eslint-disable-next-line @typescript-eslint/naming-convention
export const KEY_FORM = 'formulario';

export const enum EnumForm {
	STA_TarifaConfiguracion = 'SAC-TARIFARIO-CONFIGURACION',
	STA_TarifaLogistico = 'SAC-TARIFARIO-LOGISTICO',
	STA_Cotizacion = 'SAC-COTIZACION',
	STA_CotizacionFleteCargo = 'SAC-COTIZACION-FLETE-CARGO',
	STA_Reporte = 'SAC-REPORTE',
	STA_Control = 'SAC-MOTIVO-ESTADO',

	//Formulario de Maestros
	AgenteExtranjero = 'SMA-AGENTE-EXTRANJERO',
	Transportista = 'SMA-TRANSPORTISTA',
	AgenteCarga = 'SMA-AGENTE-CARGA',
	AgenteMaritimo = 'SMA-AGENTE-MARITIMO',
	terminalPortuario = 'SMA-TERMINAL-PORTUARIO',
	Depostio = 'SMA-DEPOSITO',
	Cliente = 'SMA-CLIENTE',
	Concepto = 'SMA-CONCEPTO',
	EntidadExtranjera = 'SMA-ENTIDAD-EXTRANJERA',
	Contacto = 'SMA-ENTIDAD-CONTACTO',
	CompaniaSeguro = 'SMA-COMPANIA-SEGURO',
	Local = 'SMA-ENTIDAD-LOCAL',
	CondicionServicio = 'SMA-CONDICION-SERVICIO',

	//Formulario de carga
	Nave = 'SCA-NAVE',
	ContratoTransporte = 'SCA-CONTRATO',

	//Formulario de configuracion interna
	Ejecutivo = 'SCI-EJECUTIVO',
}
