import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { SacCotizacionEstadoService } from 'app/services/acuerdos-comerciales/sacCotizacionEstado.service';
import { Observable, of } from 'rxjs';
import { SacCotizacionEstadoCustom } from '~models/acuerdos-comerciales/custom/SacCotizacionEstadoCustom';

@Component({
	selector: 'app-historial-estado',
	templateUrl: './historial-estado.component.html',
})
export class HistorialEstadoComponent implements OnInit {
	rowData: Observable<SacCotizacionEstadoCustom[]> = of([]);

	columnDefs: ColDef[] = [];
	nroCotizacion: UntypedFormControl = new UntypedFormControl('');
	constructor(
		@Inject(LOCALE_ID) private locale: string,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<HistorialEstadoComponent>,
		private readonly _cotizacionEstadoService: SacCotizacionEstadoService
	) {}

	ngOnInit(): void {
		this.createColumnDefs();
		this.rowData = this._cotizacionEstadoService.findAllByIdCotizacionCustom(this.data.idCotizacion);
		this.nroCotizacion.patchValue(this.data.nroCotizacion);
		this.nroCotizacion.disable();
	}

	createColumnDefs(): void {
		this.columnDefs = [
			{
				headerName: 'Estado',
				field: 'estado',
				width: 150,
			},
			{
				headerName: 'Fecha y Hora',
				field: 'fchRegistro',
				width: 150,
				valueGetter: (params: ValueGetterParams): string => {
					return params.data.fchRegistro ? formatDate(params.data.fchRegistro, 'dd/MM/yyyy hh:mm', this.locale) : '';
				},
			},
			{
				headerName: 'Usuario',
				field: 'usuario',
				width: 260,
			},
		];
	}

	close(): void {
		this.dialogRef.close();
	}
}
