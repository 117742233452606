import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map, Observable } from 'rxjs';
import { ApiResponse } from 'sumax-erp-schematics';
import { SacMotivoEstado } from '~models/acuerdos-comerciales';
import { GenericCrudService } from '~shared/classes/data-service/GenericCrud.service';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { CustomHttpClientService } from '~shared/services/custom-http-client.service';

const url = `${environment.HOST_ACUERDO_COMERCIAL}/sacmotivoestados`;
@Injectable({
	providedIn: 'root',
})
export class SacMotivoEstadoService extends GenericCrudService<SacMotivoEstado> {
	constructor(public _http: CustomHttpClientService) {
		super(url, _http);
	}
	findAllByCodTipoEstadoCotizacion(codigo: string): Observable<SacMotivoEstado[]> {
		return this._http.get<ApiResponse<SacMotivoEstado[]>>(`${url}/model-se-ro/findAllByCodTipoEstadoCotizacion/${codigo}`).pipe(map((resp) => resp.data));
	}

	getNgSelectByCodTipoEstadoCotizacion(codigo: string): Observable<NgSelectOption<SacMotivoEstado>[]> {
		return this.findAllByCodTipoEstadoCotizacion(codigo).pipe(
			map((resp) =>
				resp.map((data) => ({
					...data,
					value: data.idMotivoEstado,
					label: `${data.codigo} - ${data.descripcion}`,
				}))
			)
		);
	}

	findAllByIdTipoEstadoCotizacionList(ids: number[] | []): Observable<SacMotivoEstado[]> {
		return this._http.post<ApiResponse<SacMotivoEstado[]>>(`${this.url}/model-se-ro/findAllByIdTipoEstadoCotizacionList`, ids).pipe(map((resp) => resp.data));
	}
}
