import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiResponse, ConfigService, GenericService } from 'sumax-erp-schematics';
import { SacCotizacionEstado } from '~models/acuerdos-comerciales/SacCotizacionEstado';
import { ScProgressArrow } from '~shared/components/sc-progress-arrows/sc-progress-arrow';
import { SacCotizacionEstadoCustom } from '~models/acuerdos-comerciales/custom';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { ScaTipoEstadoCotizacion } from '~shared/interface/ScaTipoEstadoCotizacion';

@Injectable({
	providedIn: 'root',
})
export class SacCotizacionEstadoService extends GenericService<SacCotizacionEstado, number> {
	private _url = `${environment.HOST_ACUERDO_COMERCIAL}/saccotizacionestados`;

	constructor(protected _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByIdCotizacion(idCotizacion: number): Observable<SacCotizacionEstado[]> {
		return this._http.get<ApiResponse<SacCotizacionEstado[]>>(`${this._url}/model-se-ro/findAllByIdCotizacion/${idCotizacion}`).pipe(map((res) => res.data));
	}

	getAllProgressArrowByIdCotizacion(idCotizacion: number): Observable<ScProgressArrow[]> {
		return this._http.get<ApiResponse<ScProgressArrow[]>>(`${this._url}/getAllProgressArrowByIdCotizacion/${idCotizacion}`).pipe(map((res) => res.data));
	}

	findAllByIdCotizacionCustom(idCotizacion: number): Observable<SacCotizacionEstadoCustom[]> {
		return this._http.get<ApiResponse>(`${this._url}/model-se-ro/findAllByIdCotizacionCustom/${idCotizacion}`).pipe(map((res) => res.data));
	}
	updateEstadoForSacCotizacion(sacCotizacionEstado: SacCotizacionEstado): Observable<SacCotizacionEstado[]> {
		return this._http.post<ApiResponse<SacCotizacionEstado[]>>(`${this._url}/updateEstadoForSacCotizacion`, sacCotizacionEstado).pipe(map((res) => res.data));
	}

	findAllEstadosCoti(): Observable<NgSelectOption<Required<ScaTipoEstadoCotizacion>>[]> {
		return this._http.get<ApiResponse<Required<ScaTipoEstadoCotizacion>[]>>(`${this._url}/model-se-ro/findAllEstadosCoti`).pipe(
			map((res) => {
				return res.data.map((item) => ({
					...item,
					value: item.idTipoEstadoCotizacion,
					label: `${item.codigo} - ${item.nombre}`,
				}));
			})
		);
	}

	existsByIdMotivoEstado(idMotivoEstado: number): Observable<boolean> {
		return this._http.get<ApiResponse<boolean>>(`${this._url}/model-se-ro/existsByIdMotivoEstado/${idMotivoEstado}`).pipe(map((resp) => resp.data));
	}
}
