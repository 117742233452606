/* angular:styles/component:css;c7dd3e5d1eca98bbba4e7012a43965735d17d2abcf598e7275f08dcfd48352a0;/builds/sumax/erp/sumax-erp-frontend/gen/sumax-erp-frontend-acuerdocomercial/src/app/shared/components/cell-estado-render/cell-estado-render.component.ts */
.btn-state {
  height: 1.7em;
  width: 110px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 8px 8px;
}
/*# sourceMappingURL=cell-estado-render.component.css.map */
