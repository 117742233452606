<div class="d-flex justify-content-center m-1">
	<button
		class="btn btn-sm btn-state py-1 px-2 text-dark"
		[ngStyle]="{ background: color, 'border-color': color }"
		[matTooltip]="viewStatusHistory ? 'Ver historial de estados' : ''"
		matTooltipClass="bg-primary"
		(click)="viewStatusHistory ? showHistorial() : null">
		{{ estado }}
		<ng-container *ngIf="viewStatusHistory">
			<em class="fas fa-caret-down text-white" [ngStyle]="{ 'margin-left': 'auto' }"></em>
		</ng-container>
	</button>
</div>
