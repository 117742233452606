/* eslint-disable @typescript-eslint/no-explicit-any */

import { EnumAccion } from '~shared/enums/EnumAccion';

export class DialogData<T, O = any> {
	action: EnumAccion = EnumAccion.Registrar;
	subTitle = '';
	title = '';
	data?: T | null;
	object?: O;
	sacTipoEstadoCotizacion?: any;
	openedFromList: any;
	type?: string;

	// constructor(public title: string = '', public subTitle: string = '', public action?: EnumAccion, public data?: T | null, public object?: O) {}
}

export class DialogResult<T, O = any> {
	constructor(public result: boolean, public data?: T, public object?: O) {}
}
