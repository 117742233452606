import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { HistorialEstadoComponent } from '../historial-estado/historial-estado.component';

@Component({
	selector: 'app-button-border-render',
	templateUrl: './cell-estado-render.component.html',
	styles: [
		`
			.btn-state {
				height: 1.7em;
				width: 110px;
				font-weight: 400;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px 8px 8px 8px;
			}
		`,
	],
})
export class CellEstadoRenderComponent implements ICellRendererAngularComp {
	public params: any;
	estado = '';
	color!: string;
	//parametro para visualizar historial de estado
	viewStatusHistory = true;
	constructor(public matDialog: MatDialog) {}

	agInit(params: any): void {
		this.params = params;
		if (this.params.value) this.estado = this.params.value.nombre;
		this.color = this.params.value?.color ?? 'white';
		//ocultar opcion de ver historial
		if (params.value && params.value.viewStatusHistory !== undefined && params.value.viewStatusHistory === false) {
			this.viewStatusHistory = params.value.viewStatusHistory;
		}
	}

	refresh(params: ICellRendererParams): boolean {
		return false;
	}

	showHistorial(): void {
		this.matDialog.open(HistorialEstadoComponent, {
			width: '600px',
			data: {
				idCotizacion: this.params.data.idCotizacion,
				nroCotizacion: this.params.data.nroCotizacion,
			},
		});
	}
}
